import { Loader2 } from "lucide-react";
import { useEffect, useState } from "react";
import DiviApi from "../api/diviApi";
import ErrorModal from "../components/dialog/ErrorModal";
import SuccessModal from "../components/dialog/SuccessModal";
import { useOrder } from "../hooks/useOrder";
import { getRegimes, getCFDI } from "../utils/globals";
import { initialUser } from "./model/data";

const MakeInvoicePage = () => {
  const { order } = useOrder();

  const [user, setUser] = useState(initialUser);
  const [invoice, setInvoice] = useState([]);
  const [succesModal, setSuccesModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const updateUserWithOrder = () => {
      const removeOrderPrefix = (str) => {
        const prefix = "Orden ";
        if (str.startsWith(prefix)) {
          return str.slice(prefix.length);
        }
        return str;
      };
      setUser((prevUser) => ({
        ...prevUser,
        order_id: order.id,
        no_order: removeOrderPrefix(order.pos_reference),
        payment_ids: order.payment_ids,
      }));
    };

    if (order && order.id) updateUserWithOrder();
  }, [order]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: name === "vat" ? value.toUpperCase() : value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setLoading(true);
    try {
      const reponse = await DiviApi.createInvoice(user);
      const result = await DiviApi.getInvoice(reponse.id);
      setInvoice(result);
      if (user.vat) {
        setSuccesModal(true);
      } else {
        setErrorModal(true);
      }
    } catch (e) {
      setErrorModal(true);
    }
    setLoading(false);
  };

  const validate = () => {
    const newErrors = {};
    const zipCodePattern = /^\d{5}$/;
    const rfcPattern = /^[A-ZÑ&]{3,4}\d{6}[A-V1-9][A-Z1-9][0-9A]$/;

    if (!user.vat) {
      newErrors.vat = "RFC es requerido";
    } else if (!rfcPattern.test(user.vat)) {
      newErrors.vat = "RFC no es válido";
    }

    if (!user.name) newErrors.name = "Nombre es requerido";
    if (!user.cfdi_use) newErrors.cfdi_use = "Uso de CFDI es requerido";
    if (!user.regime) newErrors.regime = "Régimen es requerido";

    if (!user.zip_code) {
      newErrors.zip_code = "Código Postal es requerido";
    } else if (!zipCodePattern.test(user.zip_code)) {
      newErrors.zip_code = "Código Postal no es válido";
    }

    if (!user.email) {
      newErrors.email = "Correo eletrónico es requerido";
    } else if (!/\S+@\S+\.\S+/.test(user.email)) {
      newErrors.email = "Correo eletrónico no es válido";
    }
    return newErrors;
  };

  return (
    <main className="relative mx-auto grid max-w-7xl gap-x-16">
      <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10">
        <div className="px-4 sm:px-0">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Información personal
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            Es necesario llenar todos los datos para poder generar el ticket
            valido para el SAT.
          </p>
        </div>
        <form
          onSubmit={handleSubmit}
          className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
        >
          <div className="px-4 py-6 sm:p-8">
            <div className="grid grid-cols-1 gap-x-6 gap-y-1 sm:grid-cols-12">
              <div className="col-span-8 sm:col-span-6">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Nombre / Razón Social
                </label>
                <div className="mt-2">
                  <input
                    id="name"
                    name="name"
                    value={user.name}
                    onChange={handleChange}
                    autoComplete="name"
                    type="text"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  {errors.name && <div class="text-red-400">{errors.name}</div>}
                </div>
              </div>

              <div className="col-span-8 sm:col-span-6">
                <label
                  htmlFor="zip_code"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Código postal
                </label>
                <div className="mt-2">
                  <input
                    id="zip_code"
                    name="zip_code"
                    value={user.zip_code}
                    onChange={handleChange}
                    autoComplete="zip_code"
                    type="text"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  {errors.zip_code && (
                    <div class="text-red-400">{errors.zip_code}</div>
                  )}
                </div>
              </div>

              <div className="col-span-8 sm:col-span-6">
                <label
                  htmlFor="vat"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  RFC
                </label>
                <div className="mt-2">
                  <input
                    id="vat"
                    name="vat"
                    value={user.vat}
                    onChange={handleChange}
                    autoComplete="vat"
                    type="text"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 uppercase"
                  />
                  {errors.vat && <div class="text-red-400">{errors.vat}</div>}
                </div>
              </div>

              <div className="col-span-8 sm:col-span-6">
                <label
                  htmlFor="cfdi_use"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Uso de CFDI
                </label>
                <div className="mt-2">
                  <select
                    id="cfdi_use"
                    name="cfdi_use"
                    onChange={handleChange}
                    autoComplete="cfdi_use"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                  >
                    <option>Selecciona una opción</option>
                    {getCFDI()
                      .sort((a, b) => a.value.localeCompare(b.value))
                      .map((e) => (
                        <option key={e.key} value={e.key}>
                          {e.value}
                        </option>
                      ))}
                  </select>
                  {errors.cfdi_use && (
                    <div class="text-red-400">{errors.cfdi_use}</div>
                  )}
                </div>
              </div>

              <div className="col-span-8 sm:col-span-6">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Correo electrónico
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    value={user.email}
                    onChange={handleChange}
                    autoComplete="email"
                    type="email"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  {errors.email && (
                    <div class="text-red-400">{errors.email}</div>
                  )}
                </div>
              </div>

              <div className="col-span-8 sm:col-span-6">
                <label
                  htmlFor="country"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Regimen fiscal
                </label>
                <div className="mt-2">
                  <select
                    id="regime"
                    name="regime"
                    value={user.regime}
                    onChange={handleChange}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                  >
                    <option>Selecciona una opción</option>
                    {getRegimes()
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((e) => (
                        <option key={e.key} value={e.key}>
                          {e.name}
                        </option>
                      ))}
                  </select>
                  {errors.regime && (
                    <div class="text-red-400">{errors.regime}</div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
            <button
              type="button"
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Cancelar
            </button>
            <button
              type="submit"
              className={`w-auto flex text-center rounded-md ${
                loading
                  ? "cursor-not-allowed opacity-50 bg-indigo-500"
                  : "bg-indigo-600"
              }  px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
            >
              Generar &nbsp;{" "}
              {loading && (
                <Loader2 className="mr-2 my-auto h-4 w-4 animate-spin" />
              )}
            </button>
          </div>
        </form>
      </div>
      <SuccessModal
        invoice={invoice}
        open={succesModal}
        setOpen={setSuccesModal}
      />
      <ErrorModal open={errorModal} setOpen={setErrorModal} />
    </main>
  );
};

export default MakeInvoicePage;
